
import { defineComponent } from "vue";
import router from "@/router";

export default defineComponent({
  name: "MapaSv",
  data() {
    return {};
  },
  mounted() {
     if ((this as any).nextRoute == '') return;
     const t = document.querySelectorAll('.mapaSV > g > g');
     for (let n = 0; n != t.length; n++) {
        t[n].classList.add('hoverable');
        t[n].addEventListener('click', (e) => {
           const i = (e as any).currentTarget.id;
           router.replace((this as any).nextRoute + i);
        })
     }
  },
  props: {
    nextRoute: {
      type: String,
      default: ""
    }
  }  
  
});
